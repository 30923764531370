import { Flex, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import Footer from "components/website/Footer";
import Navbar from "components/website/Navbar";
import Section from "components/website/Section";
import Seo from "components/website/Seo";

const DataProcessingAgreement = () => {
  return (
    <>
      <Seo />
      <Navbar />

      <Section
        id="data-processing-agreement"
        display="flex"
        gap={12}
        flexDirection="column"
        maxW="3xl"
      >
        <Heading as="h1" size="4xl">
          Contratto di Nomina a Responsabile del Trattamento dei Dati Personali
        </Heading>
        <Text>Ultimo aggiornamento: 21 marzo 2025</Text>

        <Flex flexDirection="column" gap={4}>
          <Heading as="h2">Premessa</Heading>
          <Text color="gray.900">
            In ottemperanza all’art. 28 del Regolamento Generale sulla
            Protezione dei Dati Personali (GDPR), il presente contratto
            definisce i termini e le condizioni in base ai quali
          </Text>
          <UnorderedList>
            <ListItem>
              <Text color="gray.900">
                Il Cliente, in qualità di Titolare (di seguito “Titolare”)
              </Text>
            </ListItem>
          </UnorderedList>
          <Text color="gray.900">nomina</Text>
          <UnorderedList>
            <ListItem>
              <Text color="gray.900">
                Damiano Carradori (di seguito “Responsabile”)
              </Text>
            </ListItem>
          </UnorderedList>
          <Text color="gray.900">
            per l’esecuzione delle attività di trattamento dei dati personali
            necessarie per l’erogazione dei servizi.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading as="h2">1. Oggetto del Contratto</Heading>
          <Text color="gray.900">
            1.1. Il presente accordo ha per oggetto la nomina del Responsabile
            ed il conferimento allo stesso delle istruzioni relative al
            trattamento dei dati personali nell’ambito dell’utilizzo della
            Piattaforma. Le attività di trattamento che il Responsabile potrà
            effettuare sono limitate a quelle strettamente necessarie
            all’erogazione dei servizi connessi ai singoli Progetti attivi e
            secondo le istruzioni documentate del Titolare.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading as="h2">2. Durata</Heading>
          <Text color="gray.900">
            2.1. Il presente accordo si intende valido e vincolante per tutta la
            durata del rapporto contrattuale tra le Parti e si applica a ciascun
            Progetto attivato dal Titolare tramite la Piattaforma. Per ogni
            Progetto, il trattamento dei dati ha inizio alla data di attivazione
            dello stesso e termina alla sua scadenza, secondo quanto previsto
            nei Termini e Condizioni.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading as="h2">
            3. Tipologie di Dati e Categorie di Interessati
          </Heading>

          <Text color="gray.900">
            3.1. Le categorie di dati personali trattati sono:
          </Text>

          <UnorderedList spacing={3}>
            <ListItem>
              <Text color="gray.900">
                Dati identificativi (nome, cognome, email, ecc.)
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">Dati statistici e di navigazione</Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Altri dati sensibili che potrebbero essere richiesti dal
                Titolare durante l’utilizzo della Piattaforma
              </Text>
            </ListItem>
          </UnorderedList>

          <Text color="gray.900">
            3.2. I dati personali raccolti e trattati si riferiscono a:
          </Text>

          <UnorderedList spacing={3}>
            <ListItem>
              <Text color="gray.900">Clienti</Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">Clienti potenziali</Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">Utenti internet</Text>
            </ListItem>
          </UnorderedList>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading as="h2">4. Trasferimenti dei Dati</Heading>
          <Text color="gray.900">
            4.1. Il Responsabile si impegna a non trasferire dati personali al
            di fuori dello Spazio Economico Europeo (SEE), salvo preventiva
            autorizzazione scritta del Titolare o per specifiche attività che
            necessitino di tale trasferimento, sempre conformi alle basi di
            legittimità previste dal GDPR.
          </Text>
          <Text color="gray.900">
            4.2. Le parti si danno reciprocamente atto che il trattamento dei
            dati personali oggetto del presente accordo non avverrà al di fuori
            dello SEE.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading as="h2">5. Misure tecniche ed organizzative</Heading>
          <Text color="gray.900">
            5.1. Prima di dare esecuzione alla presente nomina, il Responsabile
            sarà tenuto ad implementare tutte le misure tecniche ed
            organizzative adeguate per la protezione dei dati personali. Il
            Responsabile fornirà, su richiesta del Titolare, un documento che
            descrive dettagliatamente tutte le misure di sicurezza adottate
            dallo stesso Responsabile, con specifico riferimento all'esecuzione
            del presente contratto. Qualora mediante ispezione o revisione il
            Titolare dovesse constatare la necessità di modificarle, le
            modifiche saranno apportate di comune accordo tra le parti.
          </Text>
          <Text color="gray.900">
            5.2. Il Responsabile garantisce la sicurezza del trattamento ai
            sensi degli artt. 28 par. 3 punto c) e 32 RGPD, in particolare ai
            sensi dell'art. 5 par. 1 e par. 2 RGPD. Tali misure devono garantire
            la sicurezza dei dati ed un livello di protezione adeguato al
            rischio per la confidenzialità, integrità, disponibilità e
            resilienza dei sistemi. Ai sensi dell'art. 32 par. 1 RGPD, nel
            valutare il livello di adeguatezza delle misure di sicurezza deve
            tenersi conto dello stato dell'arte, i costi di realizzazione, la
            natura, l'oggetto e gli scopi del trattamento, così come la
            probabilità di una violazione di dati personali e la gravità dei
            rischi da essa potenzialmente derivanti per i diritti e le libertà
            delle persone fisiche.
          </Text>
          <Text color="gray.900">
            5.3. Le misure tecniche ed organizzative sono soggette a evoluzione
            e progresso tecnico e tecnologico. Pertanto, il Responsabile può
            adottare opportune misure alternative adeguate al mutato contesto
            tecnologico. In tali casi, il livello di sicurezza del trattamento
            non può essere ridotto.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading as="h2">6. Diritti degli Interessati e Assistenza</Heading>
          <Text color="gray.900">
            6.1. Il Responsabile s'impegna a cooperare con il Titolare ed a
            fornire la più ampia assistenza, nei limiti in cui ciò sia
            ragionevole o possibile, al fine di agevolare il Titolare nel
            riscontro delle richieste degli interessati per l'esercizio dei loro
            diritti.
          </Text>

          <Text color="gray.900">
            6.2. In particolare, il Responsabile s'impegna a (i) comunicare
            immediatamente al Titolare ciascuna richiesta pervenutagli dagli
            interessati in merito all'esercizio dei loro diritti e, se fattibile
            o del caso, ad (ii) assistere il Titolare nel progettare e
            implementare tutte le misure tecniche ed organizzative necessarie
            per rispondere a tali richieste.
          </Text>

          <Text color="gray.900">
            6.3. Fermo restando che la responsabilità di riscontrare e
            soddisfare le richieste degli interessati grava esclusivamente sul
            Titolare, il Responsabile può essere incaricato di evadere alcune
            specifiche richieste, sempre che ciò non richieda sforzi
            sproporzionati e su istruzioni specifiche fornite per iscritto dal
            Titolare.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading as="h2">7. Sub-Responsabili</Heading>
          <Text color="gray.900">
            7.1. Il Titolare autorizza fin d’ora il Responsabile a ricorrere a
            terzi responsabili del trattamento. I sub-responsabili come
            richiesto dalla normativa, dovranno essere soggetti ai medesimi
            obblighi contrattuali contenuti nel presente contratto ai sensi
            dell'art. 28 par. 4 RGPD.
          </Text>

          <Text color="gray.900">
            7.2. Alla data di sottoscrizione del presente accordo, le parti si
            danno reciprocamente atto che il Responsabile si avvale dei seguenti
            sub-responsabili, con i quali s'impegna a concludere accordi
            contrattuali conformi al dettato dell'art. 28, par. 4 RGPD:
          </Text>

          <UnorderedList spacing={3}>
            <ListItem>
              <Text color="gray.900">
                Anna Corniani, Piazza 1° Maggio 9, 13900 Biella (BI), Italia,
                P.IVA: 02646050027
              </Text>
            </ListItem>
          </UnorderedList>

          <Text color="gray.900">
            7.3. Resta inteso che la comunicazione dei dati ad un terzo
            responsabile potrà avvenire solo una volta che tutte le condizioni
            per la nomina di cui al punto (7.1) del presente paragrafo siano
            realizzate.
          </Text>

          <Text color="gray.900">
            7.4. Responsabile dovrà mantenere aggiornato un elenco dei
            sub-responsabili. Qualsiasi modifica a tale elenco deve essere
            segnalata al Titolare senza indebito ritardo, dando al Titolare la
            facoltà di opporsi. In caso di opposizione, il Responsabile ha
            diritto di recedere dal contratto con il Titolare senza preavviso.
          </Text>

          <Text color="gray.900">
            7.5. Il Responsabile risponde integralmente dell'operato dei
            sub-responsabili nei confronti del Titolare.
          </Text>

          <Text color="gray.900">
            7.6. Qualora un sub-responsabile presti la propria opera al di fuori
            della UE/SEE, il Responsabile deve garantire la liceità del
            trasferimento dati al di fuori dello SEE, come descritto al punto 4.
            del presente contratto.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading as="h2">8. Poteri di Controllo del Titolare</Heading>
          <Text color="gray.900">
            8.1. Il Titolare ha il diritto di svolgere ispezioni o farle
            svolgere ad un revisore di volta in volta incaricato. Il revisore
            dovrà valutare il rispetto di questo contratto di nomina da parte
            del Responsabile nel corso delle proprie attività d'impresa per
            mezzo di verifiche causali, le quali dovranno di regola essere
            notificate in anticipo.
          </Text>

          <Text color="gray.900">
            8.2. Il Responsabile deve permettere al Titolare di verificare
            l'adempimento alle proprie obbligazioni, come previsto dall'art. 28
            RGPD. Su richiesta, il Responsabile fornisce al Titolare ogni
            informazione necessaria nonché, segnatamente, la prova di aver
            adottato le misure tecniche ed organizzative.
          </Text>

          <Text color="gray.900">
            8.3. La prova dell'adozione di tali misure, che potranno riferirsi
            anche ad attività non rientranti nell'ambito di questo contratto,
            potrà essere fornita anche per mezzo di
          </Text>

          <UnorderedList spacing={3}>
            <ListItem>
              <Text color="gray.900">
                conformità a codici di condotta approvati ai sensi dell'art. 40
                RGPD;
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                certificazioni emesse in base ad un meccanismo di certificazione
                approvato ai sensi dell'art. 42 RGPD;
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                attuali certificazioni di revisori, relazioni o estratti di
                relazioni redatte da organismi indipendenti (p. es. revisori,
                responsabili della protezione dei dati personali, dipartimento
                della sicurezza IT, revisori della protezione dei dati);
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                idonee certificazioni emesse da revisori della sicurezza IT o
                della protezione dei dati personali.
              </Text>
            </ListItem>
          </UnorderedList>

          <Text color="gray.900">
            8.4. Il Responsabile può addebitare al Titolare un compenso di
            entità ragionevole per l'esecuzione delle ispezioni.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading as="h2">9. Assistenza al Titolare</Heading>
          <Text color="gray.900">
            9.1. Il Responsabile assiste il Titolare nell'adempimento degli
            obblighi relativi alla sicurezza dei dati personali, nella
            segnalazione di violazioni dei dati, nelle valutazioni d'impatto
            sulla protezione dei dati e nelle consultazioni preventive di cui
            agli articoli da 32 a 36 RGPD, tra l'altro
          </Text>

          <UnorderedList spacing={3}>
            <ListItem>
              <Text color="gray.900">
                garantendo adeguati standard di protezione mediante misure
                tecniche e organizzative, tenendo conto della natura, delle
                circostanze e delle finalità del trattamento, della probabilità
                di violazioni dei dati e della gravità del rischio per le
                persone fisiche che ne può derivare;
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                garantendo l'immediata individuazione delle violazioni;
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                riferendo senza indebito ritardo al Titolare ogni violazioni di
                dati;
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                assistendo il Titolare nell'evadere le richieste degli
                interessati di esercizio dei loro diritti.
              </Text>
            </ListItem>
          </UnorderedList>

          <Text color="gray.900">
            9.2. Il Responsabile può richiedere al Titolare un compenso
            ragionevole per servizi di assistenza che non sono compresi nella
            descrizione dei servizi e che non sono dovuti a errori, violazioni o
            condotte imputabili al Responsabile.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading as="h2">10. Poteri direttivi del Titolare</Heading>
          <Text color="gray.900">
            10.1. Il Responsabile non tratta alcun dato personale ai sensi della
            presente nomina se non su istruzione documentata del Titolare, salvo
            che sia obbligato a farlo dal diritto dell'Unione o degli Stati
            membri.
          </Text>

          <Text color="gray.900">
            10.2. Nel caso in cui il Titolare richieda una modifica del
            trattamento dei dati personali, il Responsabile informa
            immediatamente il Titolare qualora ritenga che tale modifica possa
            comportare violazioni delle disposizioni in materia di protezione
            dei dati. Il Responsabile può astenersi dallo svolgere qualsiasi
            attività che possa dar luogo a tale violazione.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading as="h2">11. Responsabilità</Heading>
          <Text color="gray.900">
            11.1. Ciascuna parte del presente contratto si impegna a risarcire
            l'altra parte per danni o spese derivanti dal proprio inadempimento
            colposo del presente contratto, compreso qualsiasi inadempimento
            colposo commesso dal proprio rappresentante legale, sub-contraenti,
            dipendenti o altri agenti. Inoltre, ciascuna parte si impegna a
            tenere indenne l'altra parte da qualsiasi pretesa fatta valere da
            terzi a causa di o in relazione a qualsiasi violazione colposa
            commessa dall'altra parte.
          </Text>

          <Text color="gray.900">
            11.2. Resta ferma la previsione dell'art. 82 RGPD.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading as="h2">
            12. Distruzione e restituzione dei dati personali
          </Heading>
          <Text color="gray.900">
            12.1. Il Responsabile non crea copie o duplicati dei dati ad
            insaputa e senza il consenso del Titolare, fatta eccezione per le
            copie di sicurezza, nella misura in cui siano necessarie a garantire
            la corretta elaborazione dei dati, nonché per i dati la cui
            conservazione è prevista dalla legge.
          </Text>

          <Text color="gray.900">
            12.2. A conclusione di ciascun Progetto per il quale è stato
            effettuato il trattamento dei dati personali, a scelta del Titolare,
            il Responsabile cancella in maniera conforme alla protezione dei
            dati o restituisce al Titolare tutti i dati personali raccolti ed
            elaborati ai sensi della presente nomina, a meno che le disposizioni
            di legge applicabili non richiedano un ulteriore conservazione dei
            dati personali.
          </Text>

          <Text color="gray.900">
            12.3. In ogni caso, il Responsabile può conservare tutte le
            informazioni utili a dimostrare la corretta e conforme esecuzione
            delle attività di trattamento anche oltre la cessazione del
            contratto.
          </Text>

          <Text color="gray.900">
            12.4. La documentazione di cui al punto (12.3) che precede, deve
            comunque essere conservata dal Responsabile in ottemperanza ai
            periodi di conservazione previsti dalla legge o altrimenti
            stabiliti. Il Responsabile può consegnare tale documentazione al
            Titolare al termine della durata del contratto per sollevarsi
            dall'obbligo contrattuale di conservazione.
          </Text>
        </Flex>
      </Section>

      <Footer />
    </>
  );
};

export default DataProcessingAgreement;
