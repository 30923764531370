import {
  Flex,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import Footer from "components/website/Footer";
import Navbar from "components/website/Navbar";
import Section from "components/website/Section";
import Seo from "components/website/Seo";

const TermsAndConditions = () => {
  return (
    <>
      <Seo />
      <Navbar />

      <Section
        id="terms-and-conditions"
        display="flex"
        gap={12}
        flexDirection="column"
        maxW="3xl"
      >
        <Heading as="h1" size="4xl">
          Termini e Condizioni
        </Heading>
        <Text>Ultimo aggiornamento: 21 marzo 2025</Text>

        <Flex flexDirection="column" gap={4}>
          <Heading>1. Premessa e definizioni</Heading>
          <Text color="gray.900">
            Il presente documento regola i termini e le condizioni di utilizzo
            della piattaforma SaaS denominata Invytly (di seguito, la
            "Piattaforma"). Ai fini del presente documento, si applicano le
            seguenti definizioni:
          </Text>
          <UnorderedList spacing={3}>
            <ListItem>
              <Text color="gray.900">
                <strong>Piattaforma:</strong> Software as a Service denominato
                Invytly, accessibile tramite internet e utilizzato per gestire
                le risposte agli inviti (RSVP) per eventi, raccogliere conferme
                di partecipazione, comunicare con gli ospiti e monitorare lo
                stato delle risposte in tempo reale.
              </Text>
            </ListItem>

            <ListItem>
              <Text color="gray.900">
                <strong>Periodo di Servizio:</strong> Il periodo durante il
                quale il Cliente ha accesso alla Piattaforma. L'accesso alla
                Piattaforma è libero e gratuito, salvo diverse disposizioni nei
                termini di utilizzo. Un Cliente può accedere e gestire i
                Progetti a cui è stato invitato, anche senza aver effettuato
                alcun pagamento.
              </Text>
            </ListItem>

            <ListItem>
              <Text color="gray.900">
                <strong>Progetto:</strong> Un'istanza creata dal Cliente
                all'interno della Piattaforma per gestire un evento e sfruttare
                tutte le funzionalità disponibili. Ogni Progetto ha una durata
                di 12 mesi a decorrere dalla data di attivazione, che avviene
                previa conferma della ricezione del pagamento da parte del
                Fornitore.
              </Text>
            </ListItem>

            <ListItem>
              <Text color="gray.900">
                <strong>Utenti Autorizzati:</strong> Persone o entità
                autorizzate dal Cliente a utilizzare la Piattaforma, secondo i
                limiti stabiliti dall'offerta commerciale.
              </Text>
            </ListItem>
          </UnorderedList>
          <Text color="gray.900">
            Utilizzando la Piattaforma o registrandosi, l'utente accetta
            integralmente i presenti Termini e Condizioni. Se non accetta tali
            termini, l'utente non deve accedere né utilizzare la Piattaforma.
          </Text>
          <Text color="gray.900">
            Le informazioni fornite attraverso l'utilizzo della Piattaforma non
            sono destinate alla distribuzione o all'utilizzo da parte di persone
            o entità residenti in giurisdizioni o paesi nei quali tale
            distribuzione o utilizzo sarebbe contrario alle leggi o normative
            locali o sottoporrebbe il Fornitore a requisiti di registrazione in
            tali giurisdizioni o paesi. Pertanto, coloro che scelgono di
            accedere alla Piattaforma da altre località lo fanno di propria
            iniziativa e sono esclusivamente responsabili della conformità alle
            leggi locali applicabili.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading>2. Oggetto del Contratto</Heading>
          <Text color="gray.900">
            Il Fornitore concede al Cliente l’accesso libero e gratuito alla
            Piattaforma tramite internet. Tuttavia, la creazione di un Progetto
            è subordinata al pagamento di un importo stabilito, secondo i
            termini economici definiti nell’allegato commerciale.
          </Text>

          <Text color="gray.900">
            Ogni Cliente che ha acquistato un Progetto può invitare altri utenti
            a collaborare alla gestione all'interno della Piattaforma, senza
            costi aggiuntivi per questi utenti. Gli Utenti Invitati potranno
            accedere al Progetto e utilizzarne le funzionalità, nei limiti
            previsti dalle autorizzazioni concesse dal Cliente.
          </Text>

          <Text color="gray.900">
            Le condizioni di utilizzo della Piattaforma e dei Progetti sono
            disciplinate nel presente documento e nei relativi allegati. Tutti
            gli utenti, inclusi quelli invitati a collaborare alla gestione di
            un Progetto, accettano di rispettare le presenti Condizioni generali
            di utilizzo della Piattaforma.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading>3. Condizioni Economiche</Heading>
          <Text color="gray.900">
            Il Cliente corrisponderà al Fornitore il prezzo stabilito per
            ciascun Progetto, come definito nell’allegato commerciale. Tutti i
            prezzi indicati non sono soggetti ad IVA ai sensi dell'art. 7-ter
            del DPR 633/72.
          </Text>

          <Text color="gray.900">
            Il pagamento è dovuto esclusivamente per la creazione di un Progetto
            e dovrà essere effettuato tramite bonifico bancario entro e non
            oltre 15 giorni dalla data di ricezione della fattura. L'attivazione
            del Progetto avverrà esclusivamente a seguito della ricezione
            effettiva del pagamento da parte del Fornitore.
          </Text>

          <Text color="gray.900">
            Se il pagamento non viene ricevuto entro il termine stabilito, il
            Fornitore si riserva il diritto di sospendere l'attivazione del
            Progetto fino alla ricezione del saldo dovuto. Eventuali ritardi nel
            pagamento non comportano alcuna modifica alla durata del Progetto,
            che decorrerà comunque dalla data di attivazione confermata dal
            Fornitore.
          </Text>

          <Text color="gray.900">
            L’acquisto di un Progetto è definitivo: non sono previsti rimborsi o
            rimborsi parziali, per alcuna ragione o circostanza, salvo
            diversamente specificato nell’allegato commerciale o richiesto dalla
            normativa vigente.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading>4. Durata e rinnovo</Heading>
          <Text color="gray.900">
            L’accesso a ciascun Progetto è garantito per un periodo di 12 mesi,
            decorrenti dalla data di attivazione, che avverrà esclusivamente
            dopo la conferma della ricezione del pagamento da parte del
            Fornitore.
          </Text>

          <Text color="gray.900">
            Il pagamento consente al Cliente di creare e gestire un Progetto per
            l’intera durata di 12 mesi. Trascorsi i 12 mesi, l’accesso ai
            contenuti e alle funzionalità del Progetto verrà automaticamente
            disattivato, senza possibilità di rinnovo automatico.
          </Text>

          <Text color="gray.900">
            Alla scadenza del Progetto, il Fornitore si riserva il diritto di
            eliminare definitivamente i dati del Cliente associati al Progetto,
            salvo diverse disposizioni concordate per la conservazione prima
            della scadenza.
          </Text>

          <Text color="gray.900">
            L’accesso alla Piattaforma resta libero anche dopo la scadenza del
            Progetto. Il Cliente potrà continuare a gestire altri Progetti a cui
            è stato invitato, senza necessità di acquistare un nuovo Progetto.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading>5. Proprietà intellettuale</Heading>
          <Heading as="h3" size="md">
            Diritti sulla Piattaforma
          </Heading>
          <Text color="gray.900">
            La proprietà intellettuale della Piattaforma appartiene
            integralmente al Fornitore, inclusi codice sorgente, database,
            funzionalità, software, design del sito web, audio, video, testo,
            fotografie e grafiche (complessivamente il "Contenuto"), nonché
            marchi commerciali, marchi di servizio e loghi in esso contenuti (i
            "Marchi").
          </Text>
          <Text color="gray.900">
            Il Contenuto e i Marchi sono protetti dalle leggi sul diritto
            d'autore e sui marchi registrati, e da ulteriori normative sui
            diritti di proprietà intellettuale e sulla concorrenza sleale
            applicabili a livello internazionale.
          </Text>
          <Text color="gray.900">
            Il Contenuto e i Marchi sono forniti al Cliente "così come sono"
            esclusivamente per scopi aziendali interni.
          </Text>
          <Heading as="h3" size="md">
            Licenza d’uso
          </Heading>
          <Text color="gray.900">
            Il Fornitore concede al Cliente, subordinatamente al rispetto delle
            presenti condizioni e termini, una licenza limitata, non esclusiva,
            non trasferibile e revocabile per (a) accedere alla Piattaforma e
            per (b) scaricare o stampare parti del Contenuto esclusivamente per
            uso aziendale interno. Nessuna parte della Piattaforma, del
            Contenuto o dei Marchi può essere copiata, riprodotta, aggregata,
            ripubblicata, caricata, pubblicata, esposta pubblicamente,
            codificata, tradotta, trasmessa, distribuita, venduta, concessa in
            licenza o sfruttata commercialmente in qualsiasi modo senza previo
            consenso scritto del Fornitore.
          </Text>
          <Text color="gray.900">
            Qualsiasi richiesta di utilizzo della Piattaforma, del Contenuto o
            dei Marchi diversa da quanto previsto nel presente documento deve
            essere inviata a:{" "}
            <Link href="mailto:legal@invytly.com" color="primary.500">
              legal@invytly.com
            </Link>
            . Qualora venga autorizzata la riproduzione o l'esposizione pubblica
            di parti della Piattaforma o del Contenuto, il Cliente è tenuto a
            indicare chiaramente il Fornitore come proprietario o licenziatario
            e a garantire la visibilità delle note di copyright.
          </Text>
          <Text color="gray.900">
            Il Fornitore si riserva ogni diritto non espressamente concesso al
            Cliente relativamente alla Piattaforma, al Contenuto e ai Marchi.
            Qualsiasi violazione di questi diritti costituirà una violazione
            materiale dei presenti termini e comporterà la cessazione immediata
            del diritto di utilizzo della Piattaforma da parte del Cliente.
          </Text>
          <Heading as="h3" size="md">
            Materiale caricato dal Cliente
          </Heading>
          <Text color="gray.900">
            Si prega di leggere attentamente questa sezione e la sezione
            relativa alle "Attività vietate" prima di utilizzare la Piattaforma,
            al fine di comprendere (a) i diritti concessi al Fornitore e (b) gli
            obblighi previsti quando si pubblica o si carica qualsiasi contenuto
            sulla Piattaforma.
          </Text>
          <Text color="gray.900">
            Contributi: Inviando direttamente qualsiasi domanda, commento,
            suggerimento, idea, feedback o altra informazione riguardante la
            Piattaforma ("Contributi"), il Cliente accetta di cedere al
            Fornitore tutti i diritti di proprietà intellettuale relativi a tali
            Contributi. Il Cliente riconosce che il Fornitore diventerà
            proprietario di tali Contributi e potrà utilizzarli e diffonderli
            senza limitazioni per qualsiasi scopo lecito, commerciale o meno,
            senza obbligo di riconoscimento o compenso.
          </Text>
          <Text color="gray.900">
            Responsabilità dei contenuti caricati: Inviando Contributi tramite
            qualsiasi parte della Piattaforma, il Cliente:
          </Text>
          <UnorderedList spacing={3}>
            <ListItem>
              <Text color="gray.900">
                Dichiara di aver letto e accettato le Attività vietate e si
                impegna a non pubblicare, inviare, caricare o trasmettere
                tramite la Piattaforma alcun Contributo che sia illegale,
                molesto, odioso, dannoso, diffamatorio, osceno, offensivo,
                discriminatorio, minaccioso nei confronti di qualsiasi persona o
                gruppo, sessualmente esplicito, falso, inesatto, ingannevole o
                fuorviante.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Rinuncia, nei limiti consentiti dalla legge applicabile, a
                qualsiasi diritto morale sui Contributi inviati.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Garantisce che i Contributi sono originali o che dispone di
                tutti i diritti e delle licenze necessarie per il loro invio, e
                di avere piena autorità per concedere al Fornitore i diritti
                sopra indicati.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Dichiara che i Contributi inviati non costituiscono informazioni
                riservate.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Si assume la piena responsabilità per i Contributi inviati e si
                impegna a manlevare e tenere indenne il Fornitore da qualsiasi
                danno o perdita derivante da (a) una violazione della presente
                sezione, (b) una violazione dei diritti di proprietà
                intellettuale di terzi, (c) una violazione della normativa
                applicabile.
              </Text>
            </ListItem>
          </UnorderedList>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading>6. Attività vietate</Heading>
          <Text color="gray.900">
            Non è consentito accedere o utilizzare la Piattaforma per scopi
            diversi da quelli per i quali la stessa è resa disponibile dal
            Fornitore. La Piattaforma non può essere utilizzata per attività
            commerciali, salvo quelle espressamente approvate o autorizzate dal
            Fornitore.
          </Text>
          <Text color="gray.900">
            In qualità di utente della Piattaforma, il Cliente accetta
            espressamente di non:
          </Text>
          <UnorderedList spacing={3}>
            <ListItem>
              <Text color="gray.900">
                Recuperare sistematicamente dati o contenuti dalla Piattaforma
                per creare o compilare, direttamente o indirettamente, una
                raccolta, una compilazione, un database o una directory senza
                previa autorizzazione scritta del Fornitore.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Ingannare, frodare o indurre in errore il Fornitore o altri
                utenti, soprattutto in qualsiasi tentativo di ottenere
                informazioni sensibili quali password degli utenti.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Aggirare, disabilitare o interferire con le funzionalità di
                sicurezza della Piattaforma, incluse quelle che impediscono o
                limitano l'uso o la copia dei contenuti.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Denigrare, diffamare o altrimenti danneggiare, secondo il
                giudizio del Fornitore, la reputazione della Piattaforma o del
                Fornitore stesso.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Utilizzare informazioni ottenute tramite la Piattaforma per
                molestare, abusare o arrecare danno a terzi.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Fare un uso improprio dei servizi di supporto o inviare false
                segnalazioni di abuso o cattiva condotta.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Utilizzare la Piattaforma in modo non conforme alle leggi e
                normative vigenti.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Effettuare attività di framing o linking non autorizzate alla
                Piattaforma.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Caricare o trasmettere (o tentare di caricare o trasmettere)
                virus, cavalli di Troia o altro materiale dannoso, incluso
                l'utilizzo eccessivo di lettere maiuscole e lo spamming, che
                interferisca con l'uso continuo e piacevole della Piattaforma da
                parte di altri utenti o che ne modifichi o comprometta le
                funzioni e le operazioni.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Fare uso automatizzato del sistema, come l'utilizzo di script
                per inviare commenti o messaggi, o l'uso di data mining, robot o
                strumenti analoghi di raccolta ed estrazione dei dati.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Rimuovere avvisi di copyright o di altri diritti proprietari da
                qualsiasi contenuto.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Tentare di impersonare un altro utente o utilizzare il nome
                utente di un'altra persona.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Caricare o trasmettere (o tentare di caricare o trasmettere)
                materiale che agisca come meccanismo passivo o attivo di
                raccolta o trasmissione di informazioni, inclusi, a titolo
                esemplificativo, formati grafici chiari (gif), pixel 1×1, web
                bug, cookie o dispositivi simili (spyware o meccanismi di
                raccolta passiva).
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Interferire con, interrompere o creare un carico eccessivo sulla
                Piattaforma o sulle reti e servizi collegati alla stessa.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Molestare, infastidire, intimidire o minacciare i dipendenti o
                gli agenti del Fornitore coinvolti nella fornitura della
                Piattaforma.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Tentare di eludere le misure della Piattaforma progettate per
                prevenire o limitare l'accesso alla stessa o a parti di essa.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Copiare o adattare il software della Piattaforma, inclusi, senza
                limitazioni, Flash, PHP, HTML, JavaScript o altro codice.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Decifrare, decompilare, disassemblare o effettuare reverse
                engineering del software che compone o costituisce parte della
                Piattaforma, salvo quanto consentito dalla normativa vigente.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Utilizzare, lanciare, sviluppare o distribuire sistemi
                automatizzati, inclusi spider, robot, cheat utility, scraper o
                lettori offline che accedano alla Piattaforma, salvo l'uso
                standard consentito dai motori di ricerca o browser internet.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Utilizzare agenti di acquisto per effettuare acquisti sulla
                Piattaforma.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Utilizzare la Piattaforma in modo non autorizzato, incluso il
                raccogliere nomi utente e indirizzi e-mail per inviare posta
                indesiderata o creare account utenti tramite mezzi automatizzati
                o con false pretese.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Utilizzare la Piattaforma per competere con il Fornitore o per
                altri scopi commerciali o imprenditoriali non autorizzati.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Utilizzare la Piattaforma per pubblicizzare o offrire beni e
                servizi.
              </Text>
            </ListItem>
            <ListItem>
              <Text color="gray.900">
                Vendere o trasferire in altro modo il proprio profilo utente.
              </Text>
            </ListItem>
          </UnorderedList>
          <Text color="gray.900">
            Il Fornitore si riserva il diritto di sospendere o chiudere
            l'account di qualsiasi utente che violi le presenti Condizioni o che
            utilizzi la Piattaforma in modo contrario alle leggi vigenti.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading>7. Trattamento dei dati personali (GDPR)</Heading>
          <Text color="gray.900">
            Le Parti si impegnano a conformarsi integralmente al Regolamento
            Generale sulla Protezione dei Dati (GDPR - Regolamento UE 2016/679).
            Il Cliente è identificato come Titolare del trattamento dei dati
            personali inseriti nel Piattaforma, mentre il Fornitore agisce come
            Responsabile del trattamento ai sensi dell'art. 28 del GDPR.
          </Text>

          <Text color="gray.900">
            Il Fornitore garantisce di adottare tutte le misure tecniche e
            organizzative necessarie per assicurare un trattamento sicuro e
            conforme alla normativa vigente. Il Fornitore tratterà i dati
            personali esclusivamente secondo le istruzioni documentate del
            Cliente e al solo fine di fornire il Piattaforma oggetto del
            presente accordo. Il Cliente conferma di avere ottenuto tutte le
            autorizzazioni necessarie e di essere responsabile della
            legittimità, correttezza e pertinenza dei dati personali inseriti
            nella Piattaforma.
          </Text>

          <Text color="gray.900">
            Per ulteriori dettagli sul trattamento dei dati, il Fornitore mette
            a disposizione un apposito documento di Nomina a Responsabile del
            Trattamento dei Dati Personali (DPA), accessibile in qualsiasi
            momento al seguente indirizzo:{" "}
            <Link href="/data-processing-agreement" color="primary.500">
              https://invytly.com/data-processing-agreement
            </Link>
            . Il Cliente, accettando i presenti Termini e Condizioni, dichiara
            di aver preso visione e di accettare integralmente i contenuti del
            DPA.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading>8. Disposizioni generali</Heading>
          <Text color="gray.900">
            Per quanto non espressamente previsto nel presente documento, si
            rimanda alle normative vigenti. Il presente accordo è regolato dalla
            legge italiana e per qualsiasi controversia sarà competente il foro
            di Verona.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading>9. Modifiche e interruzioni</Heading>
          <Text color="gray.900">
            Il Fornitore si riserva il diritto di modificare, aggiornare o
            rimuovere i contenuti e le funzionalità della Piattaforma in
            qualsiasi momento, a propria esclusiva discrezione e senza alcun
            preavviso. Tuttavia, il Fornitore non è obbligato ad aggiornare le
            informazioni presenti sulla Piattaforma. Il Fornitore non potrà
            essere ritenuto responsabile nei confronti del Cliente o di terzi
            per eventuali modifiche, variazioni di prezzo, sospensioni o
            interruzioni della Piattaforma.
          </Text>

          <Text color="gray.900">
            Il Fornitore non garantisce la disponibilità continua e ininterrotta
            della Piattaforma. Problemi di natura hardware, software, interventi
            di manutenzione o altri fattori tecnici potrebbero comportare
            interruzioni, ritardi o malfunzionamenti. Il Fornitore si riserva il
            diritto di limitare, sospendere o interrompere l’accesso alla
            Piattaforma, in tutto o in parte, in qualsiasi momento e per
            qualsiasi ragione, senza preavviso.
          </Text>

          <Text color="gray.900">
            Il Cliente riconosce e accetta che il Fornitore non sarà
            responsabile per eventuali perdite, danni o disagi derivanti
            dall’impossibilità di accedere o utilizzare la Piattaforma durante
            periodi di inattività o cessazione del servizio. Nulla nel presente
            documento può essere interpretato come un obbligo del Fornitore a
            mantenere, supportare o fornire aggiornamenti, correzioni o nuove
            versioni della Piattaforma.
          </Text>

          <Text color="gray.900">
            Il Fornitore si impegna, attraverso un servizio di manutenzione
            correttiva, a risolvere entro tempi ragionevoli eventuali errori
            residui e/o malfunzionamenti non rilevati nelle fasi di testing e
            debugging, non replicabili in ambiente di staging o non derivanti da
            cause esterne.
          </Text>

          <Text color="gray.900">
            In caso di interruzioni del servizio superiori a 48 ore consecutive,
            il Fornitore valuterà, a propria discrezione, la possibilità di
            estendere la durata del Progetto senza costi aggiuntivi per il
            Cliente. Il Fornitore si impegna a comunicare eventuali modifiche
            rilevanti alla Piattaforma con un preavviso adeguato, ove possibile.
          </Text>

          <Text color="gray.900">
            Il Fornitore si riserva il diritto di modificare i presenti Termini
            e Condizioni in qualsiasi momento. Le modifiche saranno efficaci
            dalla data di pubblicazione sulla Piattaforma. L’uso continuato
            della Piattaforma dopo tali modifiche costituirà accettazione
            implicita dei nuovi Termini.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading>10. Limitazioni di responsabilità</Heading>
          <Text color="gray.900">
            In nessun caso il Fornitore, i suoi amministratori, dipendenti o
            agenti saranno responsabili verso il Cliente o terze parti per
            eventuali danni diretti, indiretti, consequenziali, esemplari,
            incidentali, speciali o punitivi, inclusi, a titolo esemplificativo
            e non esaustivo, mancati profitti, mancati ricavi, perdita di dati o
            altri danni derivanti dall'utilizzo della Piattaforma, anche qualora
            il Fornitore fosse stato informato della possibilità di tali danni.
            Nonostante quanto eventualmente indicato diversamente nel presente
            documento, la responsabilità complessiva del Fornitore nei confronti
            del Cliente, per qualsiasi motivo e indipendentemente dalla forma
            dell'azione legale, sarà in ogni caso limitata all'importo
            eventualmente corrisposto dal Cliente al Fornitore.
          </Text>
          <Text color="gray.900">
            Il Fornitore non sarà in alcun caso responsabile per interruzioni,
            malfunzionamenti o limitazioni della Piattaforma derivanti da:
          </Text>

          <UnorderedList spacing={3}>
            <ListItem>
              <Text color="gray.900">
                servizi di terze parti, inclusi, a titolo esemplificativo, API
                di fornitori esterni (es. Meta, Google, o altri);
              </Text>
            </ListItem>

            <ListItem>
              <Text color="gray.900">
                malfunzionamenti hardware, del sistema operativo o dall’uso di
                applicazioni non aggiornate o non ufficiali;
              </Text>
            </ListItem>

            <ListItem>
              <Text color="gray.900">
                errori o difetti dovuti a incompletezza e/o inesattezza delle
                informazioni fornite dal Cliente.
              </Text>
            </ListItem>
          </UnorderedList>
          <Text color="gray.900">
            Il Cliente si impegna a manlevare e tenere indenne il Fornitore da
            qualsiasi responsabilità, danno o spesa derivante dall’uso improprio
            della Piattaforma o dalla violazione delle presenti Condizioni da
            parte sua.{" "}
          </Text>
          <Text color="gray.900">
            Le limitazioni di responsabilità indicate nel presente documento si
            applicano nei limiti consentiti dalla normativa vigente in Italia.
            Se alcune di queste esclusioni o limitazioni non fossero applicabili
            in base alla legge italiana, ciò non pregiudica la validità delle
            altre clausole di questo contratto.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Heading>11. Clausola di validità e integrità dell'accordo</Heading>
          <Text color="gray.900">
            Le parti espressamente dichiarano che le presenti Condizioni
            Generali costituiscono accordo discusso e valutato in ogni sua
            singola clausola e liberamente e consapevolmente sottoscritto.
            Qualora una qualsiasi clausola delle presenti Condizioni Generali
            venisse dichiarata invalida o inefficace, tale dichiarazione non
            inficerà la validità o l'efficacia di tutte le altre clausole, che
            sopravvivranno indipendentemente dalla dichiarazione di invalidità o
            inefficacia della prima.
          </Text>

          <Text color="gray.900">
            I presenti Termini e Condizioni sono regolati dalla legge italiana.
            Qualsiasi controversia relativa alla loro interpretazione, validità
            o esecuzione sarà di competenza esclusiva del Foro di Verona.
          </Text>

          <Text color="gray.900">
            L’uso della Piattaforma da parte del Cliente implica l’accettazione
            totale e incondizionata dei presenti Termini e Condizioni.
          </Text>
        </Flex>
      </Section>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
