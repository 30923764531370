import { Box, Container, Flex, Image, Link, Text } from "@chakra-ui/react";
import footerLogo from "images/logo/footer.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box backgroundColor="primary.800" p={6}>
      <Container maxW="7xl">
        <Flex gap={2} flexDirection="column">
          <Flex justifyContent="space-between" py={6}>
            <Flex flexDirection="column" gap={3}>
              <Image alt="invytly" w={118} src={footerLogo} />
              <Box>
                <Text color="primary.100" fontSize="xs">
                  Invytly di Damiano Carradori
                </Text>
                <Text color="primary.100" fontSize="xs">
                  P.IVA: 04881370235
                </Text>
              </Box>
            </Flex>
          </Flex>
          <Flex
            flexDirection={["column", "column", "row"]}
            justifyContent="space-between"
            gap={4}
            py={4}
          >
            <Text fontSize="xs" color="primary.100">
              &copy; {new Date().getFullYear()} Invytly. All right reserved.
            </Text>

            <Flex gap={4}>
              <Link
                href="/terms-and-conditions"
                title={t("footer-terms-and-conditions")!}
                fontSize="xs"
                color="primary.100"
              >
                {t("footer-terms-and-conditions")}
              </Link>
              <Link
                href="https://www.iubenda.com/privacy-policy/65805112"
                title="Privacy Policy"
                fontSize="xs"
                color="primary.100"
              >
                Privacy Policy
              </Link>
              <Link
                href="https://www.iubenda.com/privacy-policy/65805112/cookie-policy"
                title="Cookie Policy"
                fontSize="xs"
                color="primary.100"
              >
                Cookies
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
